import React from 'react';
import { Route } from 'react-router-dom';

import WatchdogDashboardPdf from '../pdf/watchdog/dashboard/watchdogPdf';
import NotificationPdf from '../pdf/watchdog/eventLog/notification/notification';
import LogsPdf from '../pdf/watchdog/eventLog/logs/logs';
import StorageInsightPdf from '../pdf/storageInsight/dashboard/storageInisghtPdf';
import StorageInsightMesurment from '../pdf/storageInsight/measurment/measurment';
import NotificationPdfStorageInsight from '../pdf/storageInsight/eventLog/notification/notification';
import LogsPdfStorageInsight from '../pdf/storageInsight/eventLog/logs/logs';
import DcaMeasurement from '../pdf/dca/measurement/measurement';
import DcaDashboardPdf from '../pdf/dca/dashboard/dashboard';
import OpticontrolDashboardPdf from '../pdf/opticontrol/dashboard';
import SuccesMessage from '../container/success-message/index';
import ForgotPassword from '../container/profile/authentication/overview/ForgotPassword';
import MeasurementPdf from '../pdf/watchdog/measurment/measurmentPdf';

export const publicPaths = [
  { path: '/pdf/watchdog/dashboard/:id', component: WatchdogDashboardPdf },
  { path: '/pdf/watchdog/measurment/:id', component: MeasurementPdf },
  { path: '/pdf/watchdog/notification/:id', component: NotificationPdf },
  { path: '/pdf/watchdog/log/:id', component: LogsPdf },
  { path: '/pdf/storage-insight/dashboard/:id', component: StorageInsightPdf },
  { path: '/pdf/storage-insight/measurement/:id', component: StorageInsightMesurment },
  { path: '/pdf/storage-insight/notification/:id', component: NotificationPdfStorageInsight },
  { path: '/pdf/storage-insight/log/:id', component: LogsPdfStorageInsight },
  { path: '/pdf/dca/dashboard/:id', component: DcaDashboardPdf },
  { path: '/pdf/dca/measurement/:id', component: DcaMeasurement },
  { path: '/pdf/opticontrol/dashboard/:id', component: OpticontrolDashboardPdf },
  { path: '/success-message', component: SuccesMessage },
  { path: '/forgotPassword', component: ForgotPassword },
];

const publicRoutes = () => {
  const routeComponents = [
    ...publicPaths.map(({ path, component }) => <Route exact path={path} component={component} />),
  ];
  return routeComponents;
};
export default publicRoutes;
