import React, { useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { TopMenuStyle } from './style';
import { sections, subNavigation } from '../utility/constants';

const TopMenu = ({ route }) => {
  const path = '/admin';
  const section = Object.keys(sections).find(k => sections[k].route === route);
  const subNav = section ? subNavigation[section] : null;

  useLayoutEffect(() => {
    const active = document.querySelector('.strikingDash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  return (
    <div className="pdf-generate-hide">
      <TopMenuStyle>
        <div className="strikingDash-top-menu">
          <ul className="manu-content-header">
            <li>
              <NavLink to={`${path}/${route}/dashboard`}>Dashboard</NavLink>
            </li>

            {subNav
              ? subNav.map(({ label, route: subRoute }) => (
                  <li>
                    <NavLink to={`${path}/${route}/${subRoute}`}> {label}</NavLink>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </TopMenuStyle>
    </div>
  );
};

export default TopMenu;
