import { Typography } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NotFoundPage = () => {
  const location = useLocation();
  const isAdminPath = location.pathname.startsWith('/admin');
  return (
    <div style={{ textAlign: 'center', marginTop: '50px', marginBottom: '50px' }}>
      {!isAdminPath && (
        <div style={{ marginBottom: '1rem' }}>
          <img
            className="ant-dropdown-link"
            src={require(`../static/img/custom/optiflux-logo.png`)}
            width={300}
            alt=""
          />
        </div>
      )}
      <Typography.Title level={2}>404 - Page Not Found</Typography.Title>
      <Typography.Text style={{ display: 'block', marginBottom: '1rem' }} strong>
        Sorry, the page you are looking for does not exist.
      </Typography.Text>
      {!isAdminPath && <Link to="/admin">Go to Home Page</Link>}
    </div>
  );
};

export default NotFoundPage;
