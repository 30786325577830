import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import AuthLayout from '../container/profile/authentication';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));

const FrontendRoutes = () => {
  const isLoggedin = useSelector(state => state.auth.login);
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path="/" render={() => (isLoggedin ? <Redirect to="/admin" /> : <Login />)} />
      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
