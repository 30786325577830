import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import publicRoutes from './routes/publicRoutes';
import auth from './routes/auth';
import Admin from './routes/admin';
import NotFoundPage from './routes/notFoundPage';
import AppLayout from './layout/AppLayout';

const PrivateRoute = ({ children, ...rest }) => {
  const isLoggedIn = useSelector(state => state.auth.login);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isLoggedIn ? (
          <AppLayout>{children}</AppLayout>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        );
      }}
    />
  );
};

const AppRouting = () => {
  return (
    <Switch>
      {publicRoutes()}
      <PrivateRoute path="/admin">
        <Admin />
      </PrivateRoute>
      <Route exact path="/" component={auth} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default AppRouting;
