import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import {
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Menu,
  Popover,
  Select,
  Spin,
  Tooltip,
  Typography,
  notification,
  Button as AntButton,
} from 'antd';
import FeatherIcon from 'feather-icons-react';
import * as FileSaver from 'file-saver';
import { round } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Button } from '../components/buttons/buttons';
import { Checkbox } from '../components/checkbox/checkbox';
import OptiControlTableActions from '../components/opticontrol-table-actions';
import {
  colorScoreLimit,
  hueToDecimalColorScore,
} from '../container/storage-measurements/overview/combine-charts/hueToColors';
import { optifluxColors } from '../layout/colors';
import LoginService from '../service/login';
import { API_URL, COMPANY } from './constants';
import { getSetTitle, inactiveRoomStyling, roundString, setStyle, zeroCheck } from './utility';
import { MeasurementColumnContainer, MeasurementSetContainer } from '../container/main-dashboard/style';

const loginService = new LoginService();
const { Text } = Typography;
const styleDivider = {
  margin: '10px 0px',
};

export function dateSort(a, b) {
  const dateA = moment(a);
  const dateB = moment(b);
  const timeA = dateA.isValid() ? dateA.toDate().getTime() : -1;
  const timeB = dateB.isValid() ? dateB.toDate().getTime() : -1;
  return timeA - timeB;
}

/**
 *
 * @param {string} a
 * @param {string} b
 *  @returns {number}
 */
export function naturalSort(a, b) {
  const aStr = typeof a !== 'string' ? `${a}` : a;
  const bStr = typeof b !== 'string' ? `${b}` : b;
  const sortResult = aStr.localeCompare(bStr, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  return sortResult;
}

function getClassificationNumericValue(classification) {
  switch (classification) {
    case 'Short':
      return 1;
    case 'Mid':
      return 2;
    case 'Long':
      return 3;
    default:
      return 4;
  }
}

const { Option } = Select;
export const setCompany = company => {
  // useDispatch({ type: 'COMPANY_ID', company });
  // selectCompanyHandler(dispatchHandler, company);
  localStorage.setItem(COMPANY, company);
};
export const getCompany = () => {
  return localStorage.getItem(COMPANY);
};

export const SIM_TYPES = {
  FIRMNESS: 1,
  COLOR: 2,
  FIRMNESS_RECALIBRATION: 3,
  COLOR_RECALIBRATION: 4,
  HUE: 5,
  HUE_RECALIBRATION: 6,
};

export function getCurrentFirmnnessMeanValue(batch) {
  if (!batch.room_contents_to_continousmeasurements) {
    return null;
  }
  const latestFirmnessMeasurement = batch.room_contents_to_continousmeasurements
    .filter(m => m.label === 'Firmness')
    .sort((a, b) => b.shelfCode - a.shelfCode)
    .pop();
  const simulationValue =
    batch.simulations?.find(s => s.type_id === SIM_TYPES.FIRMNESS_RECALIBRATION)?.mean ||
    batch.simulations?.find(s => s.type_id === SIM_TYPES.FIRMNESS)?.mean;

  return simulationValue || latestFirmnessMeasurement?.mean_value;
}

export function getCurrentColorMeanValue(batch) {
  if (!batch.room_contents_to_continousmeasurements) {
    return null;
  }
  const latestColorMeasurement = batch.room_contents_to_continousmeasurements
    .filter(m => m.label === 'Color')
    .sort((a, b) => b.shelfCode - a.shelfCode)
    .pop();
  const simulationValue =
    batch.simulations?.find(s => s.type_id === SIM_TYPES.HUE_RECALIBRATION)?.mean ||
    batch.simulations?.find(s => s.type_id === SIM_TYPES.HUE)?.mean ||
    batch.simulations?.find(s => s.type_id === SIM_TYPES.COLOR_RECALIBRATION)?.mean ||
    batch.simulations?.find(s => s.type_id === SIM_TYPES.COLOR)?.mean;

  return simulationValue || latestColorMeasurement?.mean_value;
}

export function collectAlertsForBatch(batch) {
  const alerts = batch?.storage_insight_alerts;
  if (alerts?.snooze || alerts?.deletedAt || !batch?.room?.active) {
    return null;
  }

  const disorderRate = alerts?.disorder_rate;
  if (
    alerts?.disorder_category === '1' &&
    alerts?.disorder_name !== 'Black stem' &&
    alerts?.disorder_name !== 'Greasiness' &&
    disorderRate !== null &&
    disorderRate <= 95
  ) {
    return batch;
  }

  const firmnessMin = Number(alerts?.firmness_min);
  const firmnessMax = Number(alerts?.firmness_max);
  const colorMin = Number(alerts?.color_min);
  const colorMax = Number(alerts?.color_max);
  const colorResult = getCurrentColorMeanValue(batch);
  const colorNum = colorResult ? Number(colorResult) : null;
  const colorMean = colorNum > colorScoreLimit ? hueToDecimalColorScore(colorNum) : colorNum;
  const firmnessResult = getCurrentFirmnnessMeanValue(batch);
  const firmnessMean = firmnessResult ? Number(firmnessResult) : null;

  const firmnessOutOfLimits = firmnessMean
    ? (!!firmnessMax && firmnessMean > firmnessMax) || (!!firmnessMin && firmnessMean < firmnessMin)
    : false;
  const colorOutOfLimits = colorMean
    ? (!!colorMax && colorMean > colorMax) || (!!colorMin && colorMean < colorMin)
    : false;

  return firmnessOutOfLimits || colorOutOfLimits ? batch : null;
}

export const getMetricState = payload => {
  if (payload && Object.keys(payload).includes('isMetric')) {
    return payload.isMetric;
  }
  return true;
};

export const getGasFractionsWithSetColumns = (handleNextPage, isMetric) => {
  return [
    {
      title: (
        <MeasurementColumnContainer>
          <span>
            O<sub>2</sub> [%]
          </span>
        </MeasurementColumnContainer>
      ),
      dataIndex: 'id',
      key: 'o2meas',
      sorter: (a, b) => {
        const valueA = a.room_measurements[0]?.o2 ? (a.room_measurements[0]?.o2 / 10000).toFixed(2) : '----';
        const valueB = b.room_measurements[0]?.o2 ? (b.room_measurements[0]?.o2 / 10000).toFixed(2) : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        const externalSet = record?.machine_measurements[0]?.o2_set;
        const manualSet = record?.room_settings[0]?.o2_setpoint;
        const uloSet = record?.room_measurements_set[0]?.o2_set;
        const selectInfo = externalSet ? 'external_set_gas' : manualSet ? 'manual_set' : 'default';
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div>
              {record?.room_measurements[0]?.o2 ? (
                <MeasurementSetContainer>
                  <span
                    className={
                      record?.active === false
                        ? 'lightgrey'
                        : record?.watchdog_alerts?.o2_max && record?.watchdog_alerts?.o2_min
                        ? Number(record?.watchdog_alerts?.o2_max) <
                            Number(record?.room_measurements[0]?.o2 / (10000).toFixed(2)) ||
                          Number(record?.watchdog_alerts?.o2_min) >
                            Number(record?.room_measurements[0]?.o2 / (10000).toFixed(2))
                          ? 'cursor-pointer hover-on-cell clr-red'
                          : 'cursor-pointer hover-on-cell '
                        : 'cursor-pointer hover-on-cell'
                    }
                    tabIndex="0"
                    role="button"
                    onKeyUp={e => {
                      if (e.key === 'Enter') {
                        handleNextPage('default', record);
                      }
                    }}
                    onClick={() => {
                      handleNextPage('default', record);
                    }}
                  >
                    {(record?.room_measurements[0]?.o2 / 10000).toFixed(2)}
                  </span>
                  <Divider type="vertical" />
                  <Tooltip placement="bottom" title={getSetTitle(externalSet, manualSet, uloSet)}>
                    <span
                      tabIndex="0"
                      role="button"
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          handleNextPage(selectInfo, record);
                        }
                      }}
                      onClick={() => {
                        handleNextPage(selectInfo, record);
                      }}
                      className="cursor-pointer"
                      style={setStyle(externalSet, manualSet, uloSet)}
                    >
                      {externalSet || manualSet || uloSet}
                    </span>
                  </Tooltip>
                </MeasurementSetContainer>
              ) : (
                '----'
              )}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <MeasurementColumnContainer>
          <span>
            CO<sub>2</sub> [%]
          </span>
        </MeasurementColumnContainer>
      ),
      dataIndex: 'id',
      key: 'co2meas',
      sorter: (a, b) => {
        const valueA = a.room_measurements[0]?.co2 ? (a.room_measurements[0]?.co2 / 10000).toFixed(2) : '----';
        const valueB = b.room_measurements[0]?.co2 ? (b.room_measurements[0]?.co2 / 10000).toFixed(2) : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        const externalSet = record?.machine_measurements[0]?.co2_set;
        const manualSet = record?.room_settings[0]?.co2_setpoint;
        const uloSet = record?.room_measurements_set[0]?.co2_set;
        const selectInfo = externalSet ? 'external_set_gas' : manualSet ? 'manual_set' : 'default';
        return {
          props: {
            style: { color: record?.active ? '' : 'lightgrey' },
          },
          children: (
            <div>
              {record?.room_measurements[0]?.co2 ? (
                <MeasurementSetContainer>
                  <span
                    className={
                      record.active === false
                        ? 'lightgrey'
                        : record?.watchdog_alerts?.co2_max && record?.watchdog_alerts?.co2_min
                        ? Number(record?.watchdog_alerts?.co2_max) <
                            Number(record?.room_measurements[0]?.co2 / (10000).toFixed(2)) ||
                          Number(record?.watchdog_alerts?.co2_min) >
                            Number(record?.room_measurements[0]?.co2 / (10000).toFixed(2))
                          ? 'cursor-pointer hover-on-cell clr-red'
                          : 'cursor-pointer hover-on-cell  '
                        : 'cursor-pointer hover-on-cell'
                    }
                    role="button"
                    onClick={() => {
                      handleNextPage('default', record);
                    }}
                    tabIndex="-1"
                    onKeyUp={e => {
                      if (e.key === 'Enter') {
                        handleNextPage('default', record);
                      }
                    }}
                  >
                    {(record?.room_measurements[0]?.co2 / 10000).toFixed(2)}
                  </span>
                  <Divider type="vertical" />
                  <Tooltip placement="bottom" title={getSetTitle(externalSet, manualSet, uloSet)}>
                    <span
                      role="button"
                      onClick={() => {
                        handleNextPage(selectInfo, record);
                      }}
                      tabIndex="-1"
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          handleNextPage(selectInfo, record);
                        }
                      }}
                      className="cursor-pointer"
                      style={setStyle(externalSet, manualSet, uloSet)}
                    >
                      {externalSet || manualSet || uloSet}
                    </span>
                  </Tooltip>
                </MeasurementSetContainer>
              ) : (
                '----'
              )}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <MeasurementColumnContainer>
          <span>{`Temp ${isMetric ? '[°C]' : '[°F]'}`}</span>
        </MeasurementColumnContainer>
      ),
      dataIndex: 'id',
      key: 'tempmeas',
      sorter: (a, b) => {
        const valueA = a.room_sensors[0]?.temperature_pt100 || '----';
        const valueB = b.room_sensors[0]?.temperature_pt100 || '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        const externalSet = record?.machine_measurements[0]?.temperature_set;
        const manualSet = record?.room_settings[0]?.temp_setpoint;
        const uloSet = record?.room_measurements_set[0]?.temperature_set;
        const selectInfo = externalSet ? 'external_set_temp' : manualSet ? 'manual_set' : 'default';
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div>
              {record?.room_sensors[0]?.temperature_pt100 ? (
                <MeasurementSetContainer>
                  <span
                    className={
                      record.active === false
                        ? 'lightgrey'
                        : record?.watchdog_alerts?.temperature_max && record?.watchdog_alerts?.temperature_min
                        ? Number(record?.watchdog_alerts?.temperature_max) <
                            Number(record?.room_sensors[0]?.temperature_pt100) ||
                          Number(record?.watchdog_alerts?.temperature_min) >
                            Number(record?.room_sensors[0]?.temperature_pt100)
                          ? 'cursor-pointer hover-on-cell clr-red'
                          : 'cursor-pointer hover-on-cell '
                        : 'cursor-pointer hover-on-cell'
                    }
                    role="button"
                    onClick={() => {
                      handleNextPage('default', record);
                    }}
                    tabIndex="-1"
                    onKeyUp={e => {
                      if (e.key === 'Enter') {
                        handleNextPage('default', record);
                      }
                    }}
                  >
                    {record?.room_sensors[0]?.temperature_pt100}
                  </span>
                  <Divider type="vertical" />
                  <Tooltip placement="bottom" title={getSetTitle(externalSet, manualSet, uloSet)}>
                    <span
                      role="button"
                      onClick={() => {
                        handleNextPage(selectInfo, record);
                      }}
                      tabIndex="-1"
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          handleNextPage(selectInfo, record);
                        }
                      }}
                      style={setStyle(externalSet, manualSet, uloSet)}
                      className="cursor-pointer"
                    >
                      {externalSet || manualSet || uloSet}
                    </span>
                  </Tooltip>
                </MeasurementSetContainer>
              ) : (
                '----'
              )}
            </div>
          ),
        };
      },
    },
  ];
};

export const dashboardTableColumns = (
  showEditModal,
  handleUploadRoomDataModal,
  handleCheckbox,
  state,
  handleNextPage,
  handleAlertModalWatchdog,
) => {
  const user = loginService.getDecodedToken();
  const isMetric = getMetricState(user?.payload);
  return [
    {
      title: 'Room',
      dataIndex: 'name',
      key: 'room',
      sorter: (a, b) => naturalSort(a.name, b.name),
      render: (text, record) => {
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div
              className="cursor-pointer hover-on-cell"
              role="button"
              onClick={() => {
                handleNextPage('default', record);
              }}
              tabIndex="0"
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleNextPage('default', record);
                }
              }}
            >
              {record.name}
            </div>
          ),
        };
      },
    },
    {
      title: 'Status',
      dataIndex: '',
      key: 'status',
      sorter: (a, b) => naturalSort(a.name, b.name),
      render: (text, record) => {
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div
              className="cursor-pointer hover-on-cell"
              role="button"
              onClick={() => {
                handleNextPage('default', record);
              }}
              tabIndex="0"
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleNextPage('default', record);
                }
              }}
            >
              {record?.Channel?.state || '----'}
            </div>
          ),
        };
      },
    },
    ...getGasFractionsWithSetColumns(handleNextPage, isMetric),
    {
      title: 'Ethylene [ppm]',
      dataIndex: 'id',
      key: 'ethylene',
      sorter: (a, b) => {
        const valueA = a.room_measurements[0]?.c2h4 ? a.room_measurements[0]?.c2h4 : '----';
        const valueB = b.room_measurements[0]?.c2h4 ? b.room_measurements[0]?.c2h4 : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div
              className={
                record.active === false
                  ? 'lightgrey'
                  : record?.watchdog_alerts?.ethlyene_max && record?.watchdog_alerts?.ethlyene_min
                  ? Number(record?.watchdog_alerts?.ethlyene_max) < Number(record?.room_measurements[0]?.c2h4) ||
                    Number(record?.watchdog_alerts?.ethlyene_min) > Number(record?.room_measurements[0]?.c2h4)
                    ? 'cursor-pointer hover-on-cell clr-red'
                    : 'cursor-pointer hover-on-cell '
                  : 'cursor-pointer hover-on-cell'
              }
              role="button"
              onClick={() => {
                handleNextPage('ethlene', record);
              }}
              tabIndex="-1"
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleNextPage('default', record);
                }
              }}
            >
              {record?.room_measurements[0]?.c2h4 ? record?.room_measurements[0]?.c2h4 : '----'}
            </div>
          ),
        };
      },
    },
    {
      title: 'Last Update',
      dataIndex: 'updatedAt',
      key: 'lastUpdate',
      sorter: (a, b) => dateSort(a.room_measurements[0]?.updatedAt, b.room_measurements[0]?.updatedAt),
      render: (text, record) => {
        const date = moment(record?.room_measurements[0]?.updatedAt);
        const format = user?.payload?.datetimeFormat || 'DD/MM/YY HH:mm';
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div
              className="cursor-pointer hover-on-cell"
              role="button"
              onClick={() => {
                handleNextPage('default', record);
              }}
              tabIndex="-1"
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleNextPage('default', record);
                }
              }}
            >
              {date.isValid() ? date.format(format) : '--/--/--'}
            </div>
          ),
        };
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      className: 'hide-in-pdf',
      render: (text, record) => {
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <Checkbox
              checked={state.value === record.id ? state.checked : text}
              onChange={e => handleCheckbox(e, record.id)}
              disabled={user?.payload?.role_id === 4}
            />
          ),
        };
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      className: 'hide-in-pdf',
      render: record => {
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div className="table-actions popover-icons-table">
              <Tooltip title="Set alert" placement="top">
                <Button
                  className="btn-icon"
                  type="info"
                  to="#"
                  shape="circle"
                  disabled={user?.payload?.role_id === 4}
                  onClick={() => {
                    handleAlertModalWatchdog(record);
                  }}
                >
                  <FeatherIcon icon="clock" size={16} />
                </Button>
              </Tooltip>
              <Tooltip title="Log" placement="top">
                <Button
                  disabled={user?.payload?.role_id === 4}
                  className="btn-icon"
                  onClick={() => showEditModal(record)}
                  type="info"
                  to="#"
                  shape="circle"
                >
                  <FeatherIcon icon="edit" size={16} />
                </Button>
              </Tooltip>
            </div>
          ),
        };
      },
    },
  ];
};

export const opticontrolDashboardTableColumns = (
  handleCheckbox,
  waitingForActive,
  handleDropDown,
  state,
  handleSave,
  setUpdateSetting,
  handleActiveRecord,
  btnLoader,
  handleCancelUlo,
  handleResumeUlo,
  isCancelLoading,
  isResumeLoading,
  handleChannelSettings,
  triggerInjection,
  triggerMeasurement,
  handleViewUlo,
  handleTest,
  handleAlertModal,
  handleNextPage,
) => {
  const user = loginService.getDecodedToken();
  const isMetric = getMetricState(user?.payload);
  return [
    {
      title: 'Type',
      dataIndex: 'is_optipallet',
      key: 'is_optipallet',
      sorter: (a, b) => (a.is_optipallet && !b.is_optipallet ? -1 : 1),
      render: (text, record) => {
        return <span style={inactiveRoomStyling(record)}>{record.is_optipallet ? 'OptiPallet' : 'Room'}</span>;
      },
    },
    {
      title: 'Room / Container',
      dataIndex: 'name',
      key: 'room',
      sorter: (a, b) => naturalSort(a.name, b.name),
      render: (text, record) => {
        return <span style={inactiveRoomStyling(record)}>{text}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: '',
      key: 'status',
      sorter: (a, b) => naturalSort(a.name, b.name),
      render: (text, record) => {
        return {
          props: {
            style: inactiveRoomStyling(record),
          },
          children: <div>{record?.Channel?.state || '----'}</div>,
        };
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      className: 'hide-in-pdf',
      render: (text, record) => {
        return {
          props: {
            style: inactiveRoomStyling(record),
          },
          children: (
            <div>
              {waitingForActive.state && waitingForActive.roomId === record.id ? (
                <Spin />
              ) : (
                <Checkbox
                  checked={state.value === record.id ? state.checked : text}
                  onChange={e => handleCheckbox(e, record.id)}
                  disabled={user?.payload?.role_id > 2}
                />
              )}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <div>
          O<sub>2</sub>set [%]
        </div>
      ),
      dataIndex: '',
      key: 'o2set',
      sorter: (a, b) => {
        const valueA = a.machine_measurements[0]?.o2_set || a.room_settings[0]?.o2_setpoint || '----';
        const valueB = b.machine_measurements[0]?.o2_set || b.room_settings[0]?.o2_setpoint || '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        const setValue = record?.machine_measurements[0]?.o2_set || record?.room_settings[0]?.o2_setpoint || 0;
        return (
          <div style={inactiveRoomStyling(record)}>
            {!record?.machine_measurements?.length && record?.id === state?.activeRecord ? (
              <Input
                className="text-center"
                name="o2set"
                style={{ padding: '10px 5px' }}
                disabled={record?.room_settings[0]?.enable_autodca || state.id === record?.id}
                defaultValue={record?.room_settings[0]?.o2_setpoint}
                onChange={e => {
                  e.persist();
                  setUpdateSetting(st => ({
                    ...st,
                    o2set: e?.target?.value,
                  }));
                }}
              />
            ) : (
              <div
                className="cursor-pointer hover-on-cell"
                role="button"
                tabIndex={0}
                onKeyUp={e => (e.key === 'Enter' ? handleActiveRecord(record) : null)}
                onClick={() => handleActiveRecord(record)}
              >
                {zeroCheck(setValue) ? '----' : setValue}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          O<sub>2</sub>meas [%]
        </div>
      ),
      dataIndex: '',
      key: 'o2meas',
      sorter: (a, b) => {
        const valueA = a.room_measurements[0]?.o2 ? (a.room_measurements[0]?.o2 / 10000).toFixed(2) : '----';
        const valueB = b.room_measurements[0]?.o2 ? (b.room_measurements[0]?.o2 / 10000).toFixed(2) : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            tabIndex={0}
            onKeyUp={e => (e.key === 'Enter' ? handleNextPage('default', record) : null)}
            onClick={() => handleNextPage('default', record)}
            style={inactiveRoomStyling(record)}
          >
            {record?.room_measurements[0]?.o2 ? (record?.room_measurements[0]?.o2 / 10000).toFixed(2) : '----'}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          CO<sub>2</sub>set [%]
        </div>
      ),
      dataIndex: '',
      key: 'co2_set',
      sorter: (a, b) => {
        const valueA = a.machine_measurements[0]?.co2_set || a.room_settings[0]?.co2_setpoint || '----';
        const valueB = b.machine_measurements[0]?.co2_set || b.room_settings[0]?.co2_setpoint || '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        const setValue = record?.machine_measurements[0]?.co2_set || record?.room_settings[0]?.co2_setpoint || 0;
        return (
          <div style={inactiveRoomStyling(record)}>
            {!record?.machine_measurements?.length && record?.id === state?.activeRecord ? (
              <Input
                className="text-center"
                name="co2set"
                style={{ padding: '10px 5px' }}
                defaultValue={record?.room_settings[0]?.co2_setpoint}
                onChange={e => {
                  e.persist();
                  setUpdateSetting(st => ({
                    ...st,
                    co2set: e?.target?.value,
                  }));
                }}
              />
            ) : (
              <div
                className="cursor-pointer hover-on-cell"
                role="button"
                tabIndex={0}
                onKeyUp={e => (e.key === 'Enter' ? handleActiveRecord(record) : null)}
                onClick={() => handleActiveRecord(record)}
              >
                {zeroCheck(setValue) ? '----' : setValue}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          CO<sub>2</sub>meas [%]
        </div>
      ),
      dataIndex: '',
      key: 'co2meas',
      sorter: (a, b) => {
        const valueA = a.room_measurements[0]?.co2 ? (a.room_measurements[0]?.co2 / 10000).toFixed(2) : '----';
        const valueB = b.room_measurements[0]?.co2 ? (b.room_measurements[0]?.co2 / 10000).toFixed(2) : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            tabIndex={0}
            onKeyUp={e => (e.key === 'Enter' ? handleNextPage('default', record) : null)}
            onClick={() => handleNextPage('default', record)}
            style={inactiveRoomStyling(record)}
          >
            {record?.room_measurements[0]?.co2 ? (record?.room_measurements[0]?.co2 / 10000).toFixed(2) : '----'}
          </div>
        );
      },
    },
    {
      title: <div>{`Temp set ${isMetric ? '[°C]' : '[°F]'}`}</div>,
      dataIndex: '',
      key: 'temp_set',
      sorter: (a, b) => {
        const valueA = a.machine_measurements[0]?.temperature_set || a.room_settings[0]?.temp_setpoint || '----';
        const valueB = b.machine_measurements[0]?.temperature_set || b.room_settings[0]?.temp_setpoint || '----';
        return naturalSort(`${valueA}`, `${valueB}`);
      },
      render: (text, record) => {
        return (
          <div style={inactiveRoomStyling(record)}>
            {!record?.machine_measurements?.length && record?.id === state?.activeRecord ? (
              <Input
                className="text-center"
                name="tempset"
                style={{ padding: '10px 5px' }}
                defaultValue={record?.room_settings[0]?.temp_setpoint}
                onChange={e => {
                  e.persist();
                  setUpdateSetting(st => ({
                    ...st,
                    tempset: e?.target?.value,
                  }));
                }}
              />
            ) : (
              <div
                className="cursor-pointer hover-on-cell"
                role="button"
                tabIndex={0}
                onKeyUp={e => (e.key === 'Enter' ? handleActiveRecord(record) : null)}
                onClick={() => handleActiveRecord(record)}
              >
                {record?.machine_measurements[0]?.temperature_set || record?.room_settings[0]?.temp_setpoint || '----'}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: ' ',
      width: '230px',
      render: (text, record) => {
        const isAdmin = user.payload?.role_id === 2 || user.payload?.role_id === 1;
        if (!isAdmin) {
          return null;
        }
        const uloProfileRunning = record?.appliedProfiles?.some(p => p.deletedAt === null);
        const uloProfilePaused =
          record?.appliedProfiles?.length && record?.appliedProfiles?.every(p => p.deletedAt !== null);
        const isEditMode = record.id === state.activeRecord;
        const canEdit = !!record.Channel?.name?.length;

        return (
          <>
            {isEditMode && btnLoader === true ? (
              <Spin />
            ) : (
              <div className="table-actions popover-icons-table">
                {record?.id === state?.activeRecord && (
                  <>
                    <Tooltip title="Save" placement="top">
                      <Button
                        className="btn-icon"
                        type="info"
                        to="#"
                        shape="circle"
                        onClick={() => {
                          handleSave(record);
                        }}
                      >
                        <CheckOutlined size={16} />
                      </Button>
                    </Tooltip>

                    <Tooltip title="Cancel" placement="top">
                      <Button
                        className="btn-icon"
                        type="danger"
                        to="#"
                        shape="circle"
                        onClick={() => handleActiveRecord(null)}
                      >
                        <StopOutlined size={16} />
                      </Button>
                    </Tooltip>
                  </>
                )}
                {!isEditMode && (
                  <OptiControlTableActions
                    channelState={record.Channel?.state || 'idle'}
                    onChannelSettings={
                      record.is_optipallet && record.Channel ? () => handleChannelSettings(record.id) : null
                    }
                    onPauseUloProfile={uloProfileRunning ? () => handleCancelUlo(record.id) : null}
                    onResumeUloProfile={uloProfilePaused ? () => handleResumeUlo(record.id) : null}
                    onEditRoomSettings={canEdit ? () => handleActiveRecord(record) : null}
                    onForceInjection={
                      record.is_optipallet && record.Channel
                        ? () => triggerInjection(record.Channel?.id, record.Channel?.state === 'idle')
                        : null
                    }
                    onForceMeasurement={
                      record?.Channel
                        ? () => triggerMeasurement(record.Channel?.id, record.Channel?.state === 'idle')
                        : null
                    }
                    onViewUlo={uloProfileRunning ? () => handleViewUlo(record) : null}
                    onTest={record.Channel && record.is_optipallet ? () => handleTest(record.Channel) : null}
                    handleAlertModal={() => handleAlertModal(record)}
                  />
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];
};

export const DcaDashboardTableColumns = (
  handleNextPage,
  handleDcaStart,
  setModalInfo,
  dcaStartTrigger,
  activeDcaRooms,
  deleteMutation,
) => {
  const user = loginService.getDecodedToken();
  return [
    {
      title: 'Room',
      dataIndex: 'name',
      key: 'room',
      sorter: (a, b) => naturalSort(a.name, b.name),
      render: (text, record) => {
        return (
          <div className="cursor-pointer">
            <span
              role="button"
              className="cursor-pointer hover-on-cell"
              onClick={() => {
                handleNextPage('default', record);
              }}
              tabIndex="-1"
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleNextPage('default', record);
                }
              }}
            >
              {text && text}
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          End of last RQ <br /> measurement
        </div>
      ),
      dataIndex: 'RQLast',
      key: 'RQLast',
      sorter: (a, b) => dateSort(a.room_dca_rq[0]?.datetime, b.room_dca_rq[0]?.datetime),
      render: (text, record) => {
        const lastRQ = record?.room_dca_rq[0]?.createdAt;
        const date = moment(lastRQ || '');
        const format = user.payload.datetimeFormat || 'DD/MM/YY HH:mm';
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            onClick={() => {
              handleNextPage('default', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('default', record);
              }
            }}
          >
            {date.isValid() ? date.format(format) : '-----'}
          </div>
        );
      },
    },
    {
      title: 'RQ',
      dataIndex: 'id',
      key: 'RQ',
      sorter: (a, b) => {
        const valueA = a.rooms_to_room_dca[0]?.rq ? roundString(a.rooms_to_room_dca[0]?.rq) : '----';
        const valueB = b.rooms_to_room_dca[0]?.rq ? roundString(b.rooms_to_room_dca[0]?.rq) : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return (
          <div
            className={
              record?.room_measurements[0]?.dca_meas === 1
                ? 'cursor-pointer hover-on-cell rq-orange'
                : 'cursor-pointer hover-on-cell'
            }
            role="button"
            onClick={() => {
              handleNextPage('second', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('default', record);
              }
            }}
          >
            {record?.rooms_to_room_dca[0]?.rq ? roundString(record?.rooms_to_room_dca[0]?.rq, 2) : '----'}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          Rate O<sub>2</sub> [ppm/s]
        </div>
      ),
      dataIndex: 'RateO2',
      key: 'RateO2',
      sorter: (a, b) => {
        const valueA = a.rooms_to_room_dca[0]?.slope_o2 ? roundString(a.rooms_to_room_dca[0]?.slope_o2) : '----';
        const valueB = b.rooms_to_room_dca[0]?.slope_o2 ? roundString(b.rooms_to_room_dca[0]?.slope_o2) : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            onClick={() => {
              handleNextPage('second', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('second', record);
              }
            }}
          >
            {record?.rooms_to_room_dca[0]?.slope_o2 ? roundString(record?.rooms_to_room_dca[0]?.slope_o2) : '----'}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          Rate CO<sub>2</sub> [ppm/s]
        </div>
      ),
      dataIndex: 'RateCO2',
      key: 'RateCO2',
      sorter: (a, b) => {
        const valueA = a.rooms_to_room_dca[0]?.slope_co2 ? roundString(a.rooms_to_room_dca[0]?.slope_co2) : '----';
        const valueB = b.rooms_to_room_dca[0]?.slope_co2 ? roundString(b.rooms_to_room_dca[0]?.slope_co2) : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            onClick={() => {
              handleNextPage('second', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('second', record);
              }
            }}
          >
            {record?.rooms_to_room_dca[0]?.slope_co2 ? roundString(record?.rooms_to_room_dca[0]?.slope_co2) : '----'}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          Rate C<sub>2</sub>H<sub>4</sub> [ppm/hour]
        </div>
      ),
      dataIndex: 'RateC2H4',
      key: 'RateC2H4',
      sorter: (a, b) => {
        const valueA = a.rooms_to_room_dca[0]?.slope_c2h4 ? roundString(a.rooms_to_room_dca[0]?.slope_c2h4) : '----';
        const valueB = b.rooms_to_room_dca[0]?.slope_c2h4 ? roundString(b.rooms_to_room_dca[0]?.slope_c2h4) : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            onClick={() => {
              handleNextPage('second', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('second', record);
              }
            }}
          >
            {record?.rooms_to_room_dca[0]?.slope_c2h4 ? roundString(record?.rooms_to_room_dca[0]?.slope_c2h4) : '----'}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          Advised O<sub>2</sub>
          set [%]
        </div>
      ),
      dataIndex: 'newO2',
      key: 'newO2',
      sorter: (a, b) => {
        const valueA = a.dca_update[0]?.o2_setpoint ? a.dca_update[0]?.o2_setpoint : '----';
        const valueB = b.dca_update[0]?.o2_setpoint ? b.dca_update[0]?.o2_setpoint : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            onClick={() => {
              handleNextPage('default', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('default', record);
              }
            }}
          >
            {record?.dca_update[0]?.o2_setpoint ? record?.dca_update[0]?.o2_setpoint : '----'}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          O<sub>2</sub>set [%]
        </div>
      ),
      dataIndex: 'newCO2',
      key: 'newCO2',
      sorter: (a, b) => {
        const valueA = a.machine_measurements[0]?.o2_set || a.room_settings[0]?.o2_setpoint || '----';
        const valueB = b.machine_measurements[0]?.o2_set || b.room_settings[0]?.o2_setpoint || '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            onClick={() => {
              handleNextPage('default', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('default', record);
              }
            }}
          >
            {record?.machine_measurements[0]?.o2_set || record?.room_settings[0]?.o2_setpoint || '----'}
          </div>
        );
      },
    },
    {
      title: 'Warning',
      dataIndex: 'Warning',
      key: 'Warning',
      sorter: (a, b) => {
        const warningA = a.rooms_to_room_dca[0]?.rq_reason;
        const warningB = b.rooms_to_room_dca[0]?.rq_reason;
        return !!warningA && !warningB ? -1 : 1;
      },
      render: (text, record) => {
        const roomDca = record?.rooms_to_room_dca[0];
        return (
          <div
            className="popover-icons-table cursor-pointer"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {roomDca?.rq_reason ? (
              <Popover
                title={record?.rooms_to_room_dca[0]?.rq_reason}
                placement="top"
                getPopupContainer={node => node.parentNode}
              >
                <FeatherIcon icon="info" size={16} />
              </Popover>
            ) : (
              ''
            )}
            {roomDca?.rq_valid === '0.00' && (
              <Tooltip title="Remove RQ warning" placement="top">
                <AntButton
                  className="btn-icon"
                  type="danger"
                  size="small"
                  style={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}
                  onClick={() => deleteMutation.mutate(roomDca?.id)}
                >
                  <FeatherIcon icon="trash-2" size={14} />
                </AntButton>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          Force RQ
          <br />
          measurement
        </div>
      ),
      dataIndex: '',
      key: 'x',
      className: 'hide-in-pdf',
      render: (text, record) => {
        const dcaStatus = activeDcaRooms.find(r => r.id === record.id)?.dca_status;
        return (
          <span className="start-buttons-dca">
            {dcaStartTrigger.id === record.id && dcaStartTrigger.triggered ? (
              <Spin />
            ) : (
              <>
                {(dcaStatus === 'measuring' || dcaStatus === 'waiting') && (
                  <Tooltip
                    placement="top"
                    title={dcaStatus === 'waiting' ? 'Waiting for first measurement...' : 'Measuring...'}
                  >
                    <Button
                      className="btn-icon start"
                      type="defult"
                      to="#"
                      shape="circle"
                      disabled={user?.payload?.role_id === 4}
                      style={{ background: optifluxColors.lightGreen }}
                      onClick={() => {
                        setModalInfo({ showModal: true, selectedRoom: record, stop: true });
                      }}
                    >
                      Stop
                    </Button>
                  </Tooltip>
                )}
                {dcaStatus !== 'waiting' && dcaStatus !== 'measuring' && (
                  <Button
                    className="btn-icon start"
                    type="defult"
                    to="#"
                    shape="circle"
                    disabled={user?.payload?.role_id === 4}
                    onClick={() => {
                      setModalInfo({ showModal: true, selectedRoom: record });
                    }}
                  >
                    Start
                  </Button>
                )}
              </>
            )}
          </span>
        );
      },
    },
  ];
};

// alert dashboard
export const alertDashboardTableColumns = (
  handleDeleteAlert,
  handleSnoozeWatchDogAlert,
  currentUser,
  handleNextPage,
) => {
  const user = loginService.getDecodedToken();
  const isMetric = getMetricState(user?.payload);
  const menu = record => (
    <Menu>
      <Menu.Item onClick={() => handleSnoozeWatchDogAlert('1 day', record)}>
        <Text>1 day </Text>
      </Menu.Item>
      <Menu.Item>
        <Text onClick={() => handleSnoozeWatchDogAlert('1 week', record)}>1 week</Text>
      </Menu.Item>
    </Menu>
  );
  return [
    {
      title: 'Room',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            onClick={() => {
              handleNextPage('default', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('default', record);
              }
            }}
          >
            {record.name}
          </div>
        );
      },
    },
    ...getGasFractionsWithSetColumns(handleNextPage, isMetric),
    {
      title: <div>Ethylene [ppm]</div>,
      dataIndex: 'id',
      key: 'ethylene',
      render: (text, record) => {
        return (
          <div
            className={
              record?.watchdog_alerts?.ethlyene_max && record?.watchdog_alerts?.ethlyene_min
                ? Number(record?.watchdog_alerts?.ethlyene_max) < Number(record?.room_measurements[0]?.c2h4) ||
                  Number(record?.watchdog_alerts?.ethlyene_min) > Number(record?.room_measurements[0]?.c2h4)
                  ? 'cursor-pointer hover-on-cell clr-red'
                  : 'cursor-pointer hover-on-cell '
                : 'cursor-pointer hover-on-cell'
            }
            role="button"
            onClick={() => {
              handleNextPage('ethlene', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('ethlene', record);
              }
            }}
          >
            {record?.room_measurements[0]?.c2h4 ? record?.room_measurements[0]?.c2h4 : '----'}
          </div>
        );
      },
    },
    {
      title: 'Last Update',
      dataIndex: 'updatedAt',
      key: 'lastUpdate',
      render: (text, record) => {
        const date = moment(record?.room_measurements[0]?.updatedAt);
        const format = user?.payload?.dateFormat || 'DD/MM/YY HH:mm';
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            onClick={() => {
              handleNextPage('default', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('default', record);
              }
            }}
          >
            {date.isValid() ? date.format(format) : '--/--/--'}
          </div>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: record => {
        return (
          <div className="alert-buttons-style popover-icons-table">
            {currentUser?.payload?.role_id === 1 || currentUser?.payload?.role_id === 2 ? (
              <Tooltip title="Delete" placement="top">
                <Button
                  className="btn-icon"
                  type="danger"
                  to="#"
                  shape="circle"
                  onClick={() => {
                    handleDeleteAlert(record);
                  }}
                >
                  <FeatherIcon icon="x-circle" size={26} />
                </Button>
              </Tooltip>
            ) : (
              ''
            )}
            <Dropdown overlay={menu(record)} trigger={['click']}>
              <Tooltip title="Snooze" placement="top">
                <Button className="btn-icon" type="warning" to="#" shape="circle">
                  <FeatherIcon icon="clock" size={26} />
                </Button>
              </Tooltip>
            </Dropdown>
          </div>
        );
      },
    },
  ];
};
// alert dashboard
export const alertDashboardInnerTableColumns = () => {
  return [
    {
      title: 'Grower',
      dataIndex: ['grower', 'name'],
      key: 'grower',
    },
    {
      title: 'Orchard',
      dataIndex: ['orchard', 'name'],
      key: 'orchard',
    },
    {
      title: 'Cultivar',
      dataIndex: ['cultivar', 'title'],
      key: 'cultivar',
    },
    {
      title: 'Treatment',
      dataIndex: ['treatment', 'title'],
      key: 'treatment',
      render: (text, record) => {
        return (
          <>
            {record?.room_content_to_room_content_treatment?.map(single => (
              <div>{single?.room_content_treatment_to_treatment?.title}</div>
            ))}
          </>
        );
      },
    },
    {
      title: 'Palox',
      dataIndex: 'palox',
      key: 'palox',
    },
    {
      title: "Palox's Amount",
      dataIndex: 'amount',
      key: 'paloxamount',
    },
    {
      title: 'Harvest',
      dataIndex: 'harvest',
      key: 'harvest',
      render: text => {
        const date = moment(text);
        return date.isValid() ? date.format('DD/MM/YY HH:mm') : '--/--/--';
      },
    },
  ];
};

export const dashboardInnerTableColumns = (inModal = false, handleContentDelete, setEditContent) => {
  const user = loginService.getDecodedToken();
  const columns = [
    {
      title: 'Grower',
      dataIndex: ['grower', 'name'],
      key: 'grower',
    },
    {
      title: 'Orchard',
      dataIndex: ['orchard', 'name'],
      key: 'orchard',
    },
    {
      title: 'Cultivar',
      dataIndex: ['cultivar', 'title'],
      key: 'cultivar',
    },
    {
      title: 'Treatment',
      dataIndex: ['treatment', 'title'],
      key: 'treatment',
      render: (text, record) => {
        const isTreatments = !!record?.treatments;
        const treatments = record?.treatments || record?.room_content_to_room_content_treatment;
        return (
          <>
            {treatments?.map(single => (
              <div key={`treatmentRoom_${single?.id}`}>
                {isTreatments ? single?.title : single?.room_content_treatment_to_treatment?.title}
              </div>
            ))}
          </>
        );
      },
    },
    {
      title: 'Palox',
      dataIndex: 'palox',
      key: 'palox',
    },
    {
      title: "Palox's Amount",
      dataIndex: 'amount',
      key: 'paloxamount',
    },
    {
      title: 'Harvest',
      dataIndex: 'harvest',
      key: 'harvest',
      render: text => {
        const date = moment(text);
        const format = user?.payload?.dateFormat || 'DD/MM/YY HH:mm';
        return date.isValid() ? date.format(format) : '--/--/--';
      },
    },
  ];
  if (inModal) {
    columns.push({
      title: 'Actions',
      dataIndex: '',
      render: (_, record, i) => {
        return (
          <div className="table-actions popover-icons-table">
            <Tooltip title="Edit" placement="top">
              <Button
                className="btn-icon"
                type="info"
                to="#"
                shape="circle"
                disabled={user?.payload?.role_id === 4}
                onClick={() => {
                  setEditContent({ isEditing: true, content: record });
                }}
              >
                <FeatherIcon icon="edit" size={16} />
              </Button>
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <Button
                disabled={user?.payload?.role_id === 4}
                className="btn-icon"
                onClick={() => handleContentDelete(i)}
                type="info"
                to="#"
                shape="circle"
              >
                <FeatherIcon icon="delete" size={16} />
              </Button>
            </Tooltip>
          </div>
        );
      },
    });
  }
  return columns;
};

export const openNotificationError = (type, description) => {
  notification[type]({
    message: 'Failed!',
    description,
  });
};

export const logsTableColumn = () => {
  const downloadFile = (url, filename) => {
    try {
      FileSaver.saveAs(url, filename);
    } catch {
      openNotificationError('error', 'Unable to download file');
    }
  };
  const menu = record => (
    <Menu>
      {record.room_logs_files.map((file, index) => {
        return (
          <Menu.Item key={index}>
            <Button
              type="link"
              onClick={() =>
                downloadFile(file?.file ? `${API_URL}/uploads/room_logs/${file?.file}` : `#`, file?.original_file)
              }
            >
              <FeatherIcon icon="file" size={16} /> {file?.original_file ? file?.original_file : 'No file found'}
            </Button>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return [
    {
      title: 'Date',
      key: 'date',
      render: (text, record) => {
        const date = record.date || record.createdAt;
        return date.split(' ')[0];
      },
    },
    {
      title: 'Time',
      key: 'time',
      render: (text, record) => {
        const date = record.date || record.createdAt;
        return date.split(' ')[1];
      },
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text, record) => {
        return (
          <div className="cursor-pointer">
            <Tooltip placement="bottomRight" title={record.descreption}>
              {record.subject}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Detail',
      dataIndex: 'detail',
      key: 'detail',
      render: (text, record) => {
        return (
          <div className="attachment-icon table-actions">
            <Tooltip placement="bottomRight" title={record.detail}>
              <Button className="btn-icon" color="#000" type="info" to="#" shape="circle">
                <FeatherIcon icon="info" size={16} />
              </Button>
            </Tooltip>
            {record?.room_logs_files?.length > 0 ? (
              <Dropdown overlay={menu(record)} trigger={['click']}>
                <Button className="btn-icon" color="#000" type="default" to="#" shape="circle">
                  <FeatherIcon icon="paperclip" size={16} />
                </Button>
              </Dropdown>
            ) : (
              <Button className="btn-icon" color="#000" type="default" to="#" shape="circle" />
            )}
          </div>
        );
      },
    },
  ];
};
export const logsTableColumnPdf = (handleCreatePdfForLogs, showLogsLoader) => {
  const downloadFile = (url, filename) => {
    try {
      FileSaver.saveAs(url, filename);
    } catch {
      openNotificationError('error', 'Unable to download file');
    }
  };
  const menu = record => (
    <Menu>
      {record.room_logs_files.map((file, index) => {
        return (
          <Menu.Item key={index}>
            <Button
              type="link"
              onClick={() =>
                downloadFile(file?.file ? `${API_URL}/uploads/room_logs/${file?.file}` : `#`, file?.original_file)
              }
            >
              <FeatherIcon icon="file" size={16} /> {file?.original_file ? file?.original_file : 'No file found'}
            </Button>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return [
    // {
    //   title: (
    //     <div className="table-actions">
    //       {showLogsLoader ? (
    //         <Spin style={{ height: '40px', padding: '0px 11px', display: 'flex', alignItems: 'center' }} />
    //       ) : (
    //         <Button
    //           className="btn-icon"
    //           color="#000"
    //           type="default"
    //           to="#"
    //           shape="circle"
    //           style={{
    //             color: '#5A5F7D',
    //           }}
    //           onClick={() => {
    //             handleCreatePdfForLogs();
    //           }}
    //         >
    //           <FeatherIcon icon="download" size={16} />
    //         </Button>
    //       )}
    //     </div>
    //   ),
    //   dataIndex: '',
    //   key: '',
    // },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'date',
      render: text => {
        return text?.split(' ')[0];
      },
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'time',
      render: text => {
        return text?.split(' ')[1]?.substring(0, 5);
      },
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text, record) => {
        return (
          <div className="cursor-pointer">
            <Tooltip placement="bottomRight" title={record.descreption}>
              {record.subject}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Detail',
      dataIndex: 'detail',
      key: 'detail',
      render: (text, record) => {
        return (
          <div className="attachment-icon table-actions">
            <Tooltip placement="bottomRight" title={record.detail}>
              <Button className="btn-icon" color="#000" type="info" to="#" shape="circle">
                <FeatherIcon icon="info" size={16} />
              </Button>
            </Tooltip>
            {record?.room_logs_files?.length > 0 ? (
              <Dropdown overlay={menu(record)} trigger={['click']}>
                <Button className="btn-icon" color="#000" type="default" to="#" shape="circle">
                  <FeatherIcon icon="paperclip" size={16} />
                </Button>
              </Dropdown>
            ) : (
              <Button className="btn-icon" color="#000" type="default" to="#" shape="circle" />
            )}
          </div>
        );
      },
    },
  ];
};
export const openNotificationSuccess = (type, description) => {
  notification[type]({
    message: 'Successful!',
    description,
  });
};
export const content = data => (
  <>
    <div>
      <Text strong>Site : </Text>
      <Text>{data?.site?.name}</Text>
    </div>
    <div>
      <Text strong>Room : </Text>
      <Text>{data?.name}</Text>
    </div>
    {data?.room_contents?.map(cont => {
      return (
        <div key={`${cont?.id}`}>
          <Divider style={styleDivider} />
          {cont?.harvest && (
            <div>
              <Text strong>Harvest Date : </Text>
              <Text>{new Date(cont.harvest).toLocaleDateString()}</Text>
            </div>
          )}
          <div>
            <Text strong>Cultivar : </Text>
            <Text>{cont?.cultivar?.title}</Text>
          </div>
          <div>
            <Text strong>Grower : </Text>
            <Text>{cont?.grower?.name}</Text>
          </div>
          <div>
            <Text strong>Orchard : </Text>
            <Text>{cont?.orchard?.name}</Text>
          </div>
          <div>
            <Text strong>Treatment : </Text>
            {cont?.room_content_to_room_content_treatment?.map((row, index) => (
              <Text key={`${row?.id}${index}`}>
                {(index ? '/ ' : '') + row?.room_content_treatment_to_treatment?.title}{' '}
              </Text>
            ))}
          </div>
        </div>
      );
    })}
  </>
);
export const storageContent = data => {
  const user = loginService.getDecodedToken();
  const format = user?.payload?.dateFormat || 'DD/MM/YYYY';
  const date = moment(data?.harvest);
  return (
    <>
      {date?.isValid() && (
        <div>
          <Text strong>Harvest Date: </Text>
          <Text>{date.format(format)}</Text>
        </div>
      )}
      <div>
        <Text strong>Cultivar: </Text>
        <Text>{data?.cultivar?.title}</Text>
      </div>
      <div>
        <Text strong>Grower: </Text>
        <Text>{data?.grower?.name}</Text>
      </div>
      <div>
        <Text strong>Orchard: </Text>
        <Text>{data?.orchard?.name}</Text>
      </div>
      <div>
        <Text strong>Treatment: </Text>
        {data?.room_content_to_room_content_treatment?.map((row, index) => (
          <Text key={index}>{(index ? '/ ' : '') + row?.room_content_treatment_to_treatment?.title} </Text>
        ))}
      </div>
    </>
  );
};

export const cascaderData = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: <DatePicker />,
        label: <DatePicker />,
      },
    ],
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
  },
];

// storage insight columns
export const dashboardTableColumnsStorage = (
  showEditModal,
  handleUploadRoomDataModal,
  handleAlertModal,
  handleNextPage,
  onToggleBatch,
) => {
  const user = loginService.getDecodedToken();
  return [
    {
      title: 'Room',
      dataIndex: ['room', 'name'],
      key: 'room',
      sorter: { compare: (a, b) => naturalSort(a.room.name, b.room.name), multiple: 3 },
      render: (text, record) => {
        return (
          <div className="cursor-pointer hover-on-cell">
            <span
              role="button"
              onClick={() => {
                handleNextPage('default', record);
              }}
            >
              {text}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Cultivar',
      dataIndex: ['cultivar', 'title'],
      key: 'cultivar',
      sorter: (a, b) => naturalSort(a.cultivar.title, b.cultivar.title),
      render: (text, record) => {
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Grower',
      dataIndex: ['grower', 'name'],
      key: 'grower',
      sorter: (a, b) => naturalSort(a.grower.name, b.grower.name),
      render: (text, record) => {
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Vol',
      dataIndex: ['amount'],
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      render: (text, record) => {
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Orchard',
      dataIndex: ['orchard', 'name'],
      key: 'orchard',
      sorter: (a, b) => naturalSort(a.orchard.name, b.orchard.name),
      render: (text, record) => {
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Harvest date',
      dataIndex: ['harvest'],
      key: 'harvest',
      sorter: (a, b) => dateSort(a.harvest, b.harvest),
      render: (text, record) => {
        const date = moment(text);
        const format = user.payload.dateFormat || 'DD/MM/YYYY';
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {date.isValid() ? date.format(format) : <FeatherIcon color="lightgray" size={14} icon="minus" />}
          </span>
        );
      },
    },
    {
      title: 'Treatment',
      dataIndex: ['treatment', 'title'],
      key: 'treatment',
      sorter: (a, b) => {
        const treatmentsA = a.room_content_to_room_content_treatment
          .map(t => t.room_content_treatment_to_treatment.title)
          .join(',');
        const treatmentsB = b.room_content_to_room_content_treatment
          .map(t => t.room_content_treatment_to_treatment.title)
          .join(',');
        return naturalSort(treatmentsA, treatmentsB);
      },
      render: (text, record) => {
        const hasTreatments = record?.room_content_to_room_content_treatment?.length;
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {hasTreatments ? (
              record?.room_content_to_room_content_treatment?.map(single => (
                <div>{single?.room_content_treatment_to_treatment?.title}</div>
              ))
            ) : (
              <span style={{ color: 'lightgray' }}>None</span>
            )}
          </span>
        );
      },
    },
    {
      title: 'Firmness [kgf]',
      dataIndex: ['room', 'storage', 'firmness'],
      key: 'firmness',
      sorter: {
        compare: (a, b) => {
          const firmnessA = getCurrentFirmnnessMeanValue(a) || '-';
          const firmnessB = getCurrentFirmnnessMeanValue(b) || '-';
          return naturalSort(firmnessA, firmnessB);
        },
        multiple: 2,
      },
      render: (text, record) => {
        const firmnessStr = getCurrentFirmnnessMeanValue(record);
        const firmness = parseFloat(firmnessStr);
        return (
          <div className="cursor-pointer">
            <span
              role="button"
              className={
                record?.storage_insight_alerts?.firmness_max && record?.storage_insight_alerts?.firmness_min
                  ? Number(record?.storage_insight_alerts?.firmness_max) < Number(firmness) ||
                    Number(record?.storage_insight_alerts?.firmness_min) > Number(firmness)
                    ? 'cursor-pointer hover-on-cell clr-red'
                    : 'cursor-pointer hover-on-cell '
                  : 'cursor-pointer hover-on-cell'
              }
              onClick={() => {
                handleNextPage('default', record);
              }}
            >
              {!Number.isNaN(firmness) ? (
                round(parseFloat(firmness), 2)
              ) : (
                <FeatherIcon color="lightgray" size={14} icon="minus" />
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          Color <br /> [Color score]
        </div>
      ),
      dataIndex: ['room', 'storage', 'color'],
      key: 'color',
      sorter: {
        compare: (a, b) => {
          let colorA = parseFloat(getCurrentColorMeanValue(a));
          let colorB = parseFloat(getCurrentColorMeanValue(b));
          colorA = colorA > colorScoreLimit ? hueToDecimalColorScore(getCurrentColorMeanValue(a)) : colorA;
          colorB = colorB > colorScoreLimit ? hueToDecimalColorScore(getCurrentColorMeanValue(b)) : colorB;
          const valueA = Number.isNaN(colorA) ? Number.POSITIVE_INFINITY : colorA;
          const valueB = Number.isNaN(colorB) ? Number.POSITIVE_INFINITY : colorB;
          return valueA - valueB;
        },
        multiple: 1,
      },
      render: (text, record) => {
        const colorStr = getCurrentColorMeanValue(record);
        let color = parseFloat(colorStr);
        if (color > colorScoreLimit) {
          color = hueToDecimalColorScore(colorStr);
        }
        return (
          <div className="cursor-pointer">
            <span
              role="button"
              className={
                record?.storage_insight_alerts?.color_max && record?.storage_insight_alerts?.color_min
                  ? Number(record?.storage_insight_alerts?.color_max) < Number(color) ||
                    Number(record?.storage_insight_alerts?.color_min) > Number(color)
                    ? 'cursor-pointer hover-on-cell clr-red'
                    : 'cursor-pointer hover-on-cell '
                  : 'cursor-pointer hover-on-cell  '
              }
              onClick={() => {
                handleNextPage('default', record);
              }}
            >
              {!Number.isNaN(color) ? (
                round(parseFloat(color), 2)
              ) : (
                <FeatherIcon color="lightgray" size={14} icon="minus" />
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Disorder',
      dataIndex: ['room', 'storage', 'disorder'],
      key: 'disorder',
      render: (text, record) => {
        const duplicateRemove = record?.room_contents_to_storageinsightvcbt[0]?.storageinsightvcbt_to_categorical;
        const uniqueArray = duplicateRemove?.filter((v, i, a) => a.findIndex(t => t.label === v.label) === i);
        const latestArray = uniqueArray?.filter(x => x.measurement_date === uniqueArray[0].measurement_date);
        return (
          <div className="cursor-pointer">
            <span
              role="button"
              className="cursor-pointer hover-on-cell clr-red"
              onClick={() => {
                handleNextPage('disorder', record);
              }}
            >
              {latestArray?.length ? (
                latestArray.map(x => x.label).join(', ')
              ) : (
                <FeatherIcon icon="check" color="green" size={14} />
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Classification',
      dataIndex: ['classification', 'name'],
      key: 'classification',
      sorter: (a, b) => {
        return (
          getClassificationNumericValue(a.classification?.name) - getClassificationNumericValue(b.classification?.name)
        );
      },
      render: (text, record) => {
        // const calssification = record?.room_contents_to_simulationrecalibrationfirmness[0]
        //   ? record?.room_contents_to_simulationrecalibrationfirmness[0].intersection_date
        //   : record?.room_contents_to_simualtion_colors[0]?.intersection_date;
        return (
          <div className="cursor-pointer">
            <span
              role="button"
              className="cursor-pointer hover-on-cell"
              onClick={() => {
                handleNextPage('default', record);
              }}
            >
              {text}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (t, record) => {
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: !record.room.active ? (
            <span>Room not active</span>
          ) : (
            <Checkbox
              checked={record.active && record.room.active}
              onChange={() => onToggleBatch(record)}
              disabled={user?.payload?.role_id > 2}
            />
          ),
        };
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      className: 'hide-in-pdf',
      render: record => {
        return (
          <div className="table-actions popover-icons-table">
            <Popover title="Set alert" placement="top" getPopupContainer={node => node.parentNode}>
              <Button
                className="btn-icon"
                type="info"
                to="#"
                shape="circle"
                disabled={user?.payload?.role_id === 4}
                onClick={() => {
                  handleAlertModal(record);
                }}
              >
                <FeatherIcon icon="clock" size={16} />
              </Button>
            </Popover>

            {/* <Popover title="Import data" placement="top" getPopupContainer={node => node.parentNode}>
              <Button
                className="btn-icon"
                type="info"
                to="#"
                shape="circle"
                onClick={() => handleUploadRoomDataModal()}
              >
                <FeatherIcon icon="upload" size={16} />
              </Button>
            </Popover> */}

            <Popover title="Log" placement="top" getPopupContainer={node => node.parentNode}>
              <Button
                disabled={user?.payload?.role_id === 4}
                className="btn-icon"
                onClick={() => showEditModal(record)}
                type="info"
                to="#"
                shape="circle"
              >
                <FeatherIcon icon="edit" size={16} />
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];
};
// alert dashboard columns
export const alertDashboardTableColumnsStorage = (
  handleDeleteAlert,
  handleSnoozeStorageInsightAlert,
  user,
  handleNextPage,
) => {
  const menu = record => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleSnoozeStorageInsightAlert('1 day', record)}>
        <Text>1 day </Text>
      </Menu.Item>
      <Menu.Item key="1">
        <Text onClick={() => handleSnoozeStorageInsightAlert('1 week', record)}>1 week</Text>
      </Menu.Item>
    </Menu>
  );
  return [
    {
      title: 'Room',
      dataIndex: ['room', 'name'],
      key: 'room',
      render: (text, record) => {
        return (
          <div className="cursor-pointer hover-on-cell">
            <span
              role="button"
              onClick={() => {
                handleNextPage('default', record);
              }}
            >
              {text}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Cultivar',
      dataIndex: ['cultivar', 'title'],
      key: 'cultivar',
      render: (text, record) => {
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Grower',
      dataIndex: ['grower', 'name'],
      key: 'grower',
      render: (text, record) => {
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Vol',
      dataIndex: ['amount'],
      key: 'amount',
      render: (text, record) => {
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Orchard',
      dataIndex: ['orchard', 'name'],
      key: 'orchard',
      render: (text, record) => {
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'Harvest date',
      dataIndex: ['harvest'],
      key: 'harvest',
      render: (text, record) => {
        const date = moment(text);
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {date.isValid() ? date.format('DD/MM/YYYY') : <FeatherIcon color="lightgray" size={14} icon="minus" />}
          </span>
        );
      },
    },
    {
      title: 'Treatment',
      dataIndex: ['treatment', 'title'],
      key: 'treatment',
      render: (text, record) => {
        return (
          <span
            role="button"
            className="cursor-pointer hover-on-cell"
            onClick={() => {
              handleNextPage('default', record);
            }}
          >
            {record?.room_content_to_room_content_treatment?.map(single => (
              <div>{single?.room_content_treatment_to_treatment?.title}</div>
            ))}
          </span>
        );
      },
    },
    {
      title: 'Firmness [kgf]',
      dataIndex: ['room', 'storage', 'firmness'],
      key: 'firmness',
      render: (text, record) => {
        const firmnessStr = getCurrentFirmnnessMeanValue(record);
        const firmness = parseFloat(firmnessStr);

        const max =
          record?.storage_insight_alerts?.firmness_max !== null
            ? Number(record?.storage_insight_alerts?.firmness_max)
            : Number.MAX_VALUE;
        const min =
          record?.storage_insight_alerts?.firmness_min !== null
            ? Number(record?.storage_insight_alerts?.firmness_min)
            : Number.MIN_VALUE;
        const value = firmness;

        return (
          <div className="cursor-pointer">
            <span
              role="button"
              className={
                value < min || value > max ? 'cursor-pointer hover-on-cell clr-red' : 'cursor-pointer hover-on-cell '
              }
              onClick={() => {
                handleNextPage('default', record);
              }}
            >
              {!Number.isNaN(firmness) ? (
                `${round(firmness, 2)} ${value < min ? `(< ${min})` : ''}${value > max ? `(${max})` : ''}`
              ) : (
                <FeatherIcon color="lightgray" size={14} icon="minus" />
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Color [Color score]',
      dataIndex: ['room', 'storage', 'color'],
      key: 'color',
      render: (text, record) => {
        const colorStr = getCurrentColorMeanValue(record);
        let color = parseFloat(colorStr);
        if (color > colorScoreLimit) {
          color = hueToDecimalColorScore(colorStr);
        }
        const max =
          record?.storage_insight_alerts?.color_max !== null
            ? Number(record?.storage_insight_alerts?.color_max)
            : Number.MAX_VALUE;
        const min =
          record?.storage_insight_alerts?.color_min !== null
            ? Number(record?.storage_insight_alerts?.color_min)
            : Number.MIN_VALUE;
        return (
          <div className="cursor-pointer">
            <span
              role="button"
              className={
                color < min || color > max ? 'cursor-pointer hover-on-cell clr-red' : 'cursor-pointer hover-on-cell '
              }
              onClick={() => {
                handleNextPage('default', record);
              }}
            >
              {!Number.isNaN(color) ? (
                `${round(color, 2)} ${color < min ? `(< ${min})` : ''}${color > max ? `(> ${max})` : ''}`
              ) : (
                <FeatherIcon color="lightgray" size={14} icon="minus" />
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Disorder',
      dataIndex: ['room', 'storage', 'disorder'],
      key: 'disorder',
      render: (text, record) => {
        const duplicateRemove = record?.room_contents_to_storageinsightvcbt[0]?.storageinsightvcbt_to_categorical;
        const filteredDisorder = duplicateRemove?.filter(
          (v, i, a) =>
            a.findIndex(
              t => t.label === v.label && t.shelf_code === 0 && t.categorical_mean_values_one?.value <= 95,
            ) === i,
        );
        return (
          <div className="cursor-pointer">
            <span
              role="button"
              className="cursor-pointer hover-on-cell clr-red"
              onClick={() => {
                handleNextPage('disorder', record);
              }}
            >
              {filteredDisorder?.map(x => `${x.label}`).join(', ')}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Classification',
      dataIndex: ['classification', 'name'],
      key: 'classification',
      render: (text, record) => {
        // const calssification = record?.room_contents_to_simulationrecalibrationfirmness[0]
        //   ? record?.room_contents_to_simulationrecalibrationfirmness[0].intersection_date
        //   : record?.room_contents_to_simualtion_colors[0]?.intersection_date;
        return (
          <div className="cursor-pointer">
            <span
              role="button"
              className="cursor-pointer hover-on-cell"
              onClick={() => {
                handleNextPage('default', record);
              }}
            >
              {text}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      className: 'hide-in-pdf',
      render: record => {
        return (
          <div className="alert-buttons-style  popover-icons-table">
            {user?.payload?.role_id === 1 || user?.payload?.role_id === 2 ? (
              <Popover title="Delete" placement="top" getPopupContainer={node => node.parentNode}>
                <Button
                  className="btn-icon"
                  type="danger"
                  to="#"
                  shape="circle"
                  onClick={() => {
                    handleDeleteAlert(record);
                  }}
                >
                  <FeatherIcon icon="x-circle" size={26} />
                </Button>
              </Popover>
            ) : (
              ''
            )}
            <Dropdown overlay={menu(record)} trigger={['click']}>
              <Popover title="Snooze" placement="top" getPopupContainer={node => node.parentNode}>
                <Button className="btn-icon" type="warning" to="#" shape="circle">
                  <FeatherIcon icon="clock" size={26} />
                </Button>
              </Popover>
            </Dropdown>
          </div>
        );
      },
    },
  ];
};
